.search {
    margin-top: 7em;
    padding-inline: 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
}

.search__heading {
    font-size: 3.63rem;
    font-family: var(--font-primary);
    color: #343434;
    line-height: 1.2;
    margin-bottom: 2em;
    max-width: 18ch;
}

.search__list {
    display: flex;
    flex-direction: column;
    gap: 1.2em;
}

.list__item {
    display: flex;
    align-items: center;
    gap: 1em;
}

.item__icon {
    color: #7E1F1F;
    font-size: 1.5rem;
}

.item__text {
    font-size: 1.2rem;
    font-family: var(--font-secondary);
    color: #813030;
    font-weight: bold;
}

.search__form {
    background-color: #f8f8f8;
    padding: 3em 2em;
    font-size: 1.05rem;
    border-radius: 30px;
    box-shadow: 0px 4px 4px 0px #00000026;
}

.form__toggle {
    display: none;
}

.form__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5em 3em;
}

.form__container {
    display: flex;
    flex-direction: column;
    gap: .5em;
    position: relative;
    width: fit-content;
}

.form__label {
    font-family: var(--font-secondary);
    color: #7E2121;
    font-weight: bold;
}

.form__input,
.form__select {
    font-size: inherit;
    padding: .8em;
    border: none;
    border-radius: 20px;
}

.form__input_expanded {
    border-radius: 20px 20px 0 0;
}

.form__input::placeholder {
    font-family: var(--font-secondary);
}

.form__autocomplete {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFF;
    z-index: 3;
    width: 100%;
    top: 100%;
    border-radius: 0 0 20px 20px;
    text-align: center;
    box-shadow: 0px 5px 2px rgba(0, 0, 0, .2);
}

.form__autocomplete__button {
    border: none;
    background-color: #FFF;
    font-size: 1.15rem;
    font-family: var(--font-secondary);
    padding: .5em 3em;
    cursor: pointer;
    border-radius: 15px;
}

.form__autocomplete__button:hover,
.form__autocomplete__button:focus {
    background-color: #EEE;
}

.form__error {
    text-align: center;
    font-family: var(--font-secondary);
    margin-top: .5em;
    color: red;
    font-size: 1.3rem;
}

.form__button {
    font-size: inherit;
    color: white;
    font-family: var(--font-secondary);
    background-color: #7E2121;
    display: block;
    margin-inline: auto;
    margin-top: 2em;
    font-weight: bold;
    border: none;
    width: 100%;
    padding: .8em;
    border-radius: 30px;
    cursor: pointer;
}

@media(max-width:1120px) {
    .search__form {
        max-width: 50%;
    }

    .form__input {
        max-width: 180px;
    }

    .form__autocomplete__button {
        padding: .5em 2em;
    }

}

@media(max-width:1024px) {
    .search__heading {
        margin-bottom: 1.5em;
        font-size: 3.2rem;
    }

    .search__left {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .search {
        flex-direction: column;
    }

    .search__form {
        max-width: 100%;
    }
}

@media(max-width:535px) {
    .form__toggle {
        position: absolute;
        display: block;
        margin: 0 auto;
        font-size: 1.3rem;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        color: white;
        background-color: #7E2121;
        font-weight: bold;
        border: none;
        padding: .3em .7em;
        border-radius: 20px;
    }

    .search__heading {
        font-size: 2.5rem;
    }

    .item__text {
        font-size: 1rem;
    }

    .item__icon {
        font-size: 1.2rem;
    }

    .search__form {
        padding-inline: 5em;
        max-height: 150px;
        overflow-y: hidden;
        position: relative;
        transition: all 550ms ease;
    }

    .search__form_active {
        max-height: 1000px;
    }

    .form__grid {
        grid-template-columns: 1fr;
        margin-top: 1em;
        justify-content: center;
        justify-items: center;
    }

    .form__container {
        align-items: center;
    }
}

@media(max-width:420px) {
    .search__heading {
        font-size: 2.3rem;
    }

    .item__text {
        font-size: .76rem;
        width: fit-content;
    }

    .search__list {
        align-items: center;
    }

    .list__item {
        gap: .3em;
        text-align: center;
    }

    .item__icon {
        font-size: 1rem;
    }

    .search__form {
        width: 97%;
    }

    .form__button {
        width: fit-content;
    }
}