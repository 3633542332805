.main__top {
    padding-inline: 5em;
    margin-top: 2.5em;
}

.smallTop {
    font-size: .8rem;
    color: #A3A3A3;
    display: flex;
    align-items: center;
    gap: .3em;
    font-family: var(--font-primary);
}

.heading {
    font-size: 3.63rem;
    font-family: var(--font-primary);
    color: #343434;
    line-height: 1.3;
}

.subheading {
    font-size: 1.2rem;
    color: #000;
    font-family: var(--font-primary);
}

.form {
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-top: 2em;
    padding: 2em 5em;
}

.form__top,
.form__bottom__group {
    display: flex;
    gap: 3em;
    flex-wrap: wrap;
}

.form__container {
    display: flex;
    flex-direction: column;
    gap: .5em;
    position: relative;
    width: fit-content;
}

.form__label,
.form__bottom__label {
    font-size: 1.1rem;
    font-family: var(--font-secondary);
    color: #000;
}

.form__select,
.form__input {
    background-color: #F3F3F3;
    padding: .5em;
    font-size: 1.2rem;
    font-family: var(--font-secondary);
    border-radius: 15px;
    border: none;
    box-shadow: 0px 3px 10px 0px #00000026;
}

.form__select:focus {
    border-radius: 15px 15px 0 0;
}

.form__option {
    font-size: 1rem;
    padding: .4em;
    font-family: var(--font-secondary);
}

.form__bottom__label {
    margin-bottom: 1.5em;
}

.form__button {
    margin-top: 1.5em;
    font-size: 1rem;
    color: white;
    background-color: #7E2121;
    font-weight: bold;
    width: 30em;
    border: none;
    padding: 1.5em 3em;
    border-radius: 50px;
    cursor: pointer;
}

.form__input_expanded {
    border-radius: 15px 15px 0 0;
}

.form__input::placeholder {
    font-family: var(--font-secondary);
}

.form__autocomplete {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFF;
    z-index: 3;
    width: 100%;
    top: 100%;
    border-radius: 0 0 15px 15px;
    text-align: center;
    box-shadow: 0px 5px 2px rgba(0, 0, 0, .2);
}

.form__autocomplete__button {
    border: none;
    background-color: #FFF;
    font-size: 1.15rem;
    font-family: var(--font-secondary);
    padding: .5em 3em;
    cursor: pointer;
    border-radius: 15px;
}

.form__autocomplete__button:hover,
.form__autocomplete__button:focus {
    background-color: #EEE;
}

.noResults {
    font-size: 1.8rem;
    font-family: var(--font-primary);
    background-color: rgba(205, 0, 0, .7);
    color: white;
    width: fit-content;
    margin: 1em auto;
    margin-top: 2em;
    padding: .3em 3em;
    border-radius: 23px;
    text-align: center;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin-top: 3em;
}

.pagination__button {
    font-size: 2rem;
    display: grid;
    place-items: center;
    padding: .3em;
    cursor: pointer;
    position: relative;
    border-radius: 25px;
    border: none;
    background-color: #7E2121;
    color: white;
}


.pagination__button_disabled {
    color: initial;
    cursor: not-allowed;
    color: white;
}

.pagination__button_disabled::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(16, 16, 16, .6);
    border-radius: 25px;
}

.page {
    margin-top: .5em;
    font-size: 1.7rem;
    text-align: center;
    font-family: var(--font-secondary);
}

@media(max-width:670px) {

    .form__top,
    .form__bottom__group {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .form {
        padding: 2em;
    }

    .form__button {
        width: 100%;
    }
}

@media(max-width:570px) {

    .form__input,
    .form__select {
        width: 100%;
    }

    .main__top {
        padding-inline: 2em;
    }

    .heading {
        font-size: 2.5rem;
    }

    .subheading {
        font-size: .9rem;
    }
}

@media(max-width:500px) {
    .noResults {
        font-size: 1.4rem;
    }
}

@media(max-width:420px) {

    .form__top,
    .form__bottom__group {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}