.footer {
    margin-top: 12em;
    padding-block: 3.5em;
    margin-bottom: 3em;
    box-shadow: 0px 1px 7px 5px #00000026;
}

.footer__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    column-gap: 2em;
    row-gap: 4em;
    margin-bottom: 9em;
    padding-inline: 2em;
}

.footer__logo {
    margin-bottom: 1em;
}

.footer__first__text {
    font-size: 1.1rem;
    font-family: var(--font-secondary);
    max-width: 27ch;
    line-height: 1.6;
    color: #544545;
}

.footer__first__socialMedia {
    display: flex;
    align-items: center;
    gap: .75em;
    margin-top: .5em;
}

.footer__socialLink {
    background-color: #AC9F9F;
    display: grid;
    place-items: center;
    padding: .5em;
    border-radius: 9px;
    transition: var(--transition);
}

.footer__socialLink:hover {
    background-color: #9B8E8E;
}

.footer__socialLink__icon {
    color: white;
    font-size: 1.1rem;
}

.footer__column__heading {
    font-size: 1.2rem;
    font-family: var(--font-secondary);
    color: #731010;
    font-weight: bold;
    margin-bottom: 2em;
}

.footer__column__links {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.footer__navLink {
    font-size: 1.1rem;
    text-decoration: none;
    color: #544545;
    font-family: var(--font-secondary);
}

.footer__bottom {
    font-size: 1.1rem;
    font-family: var(--font-secondary);
    color: #767494;
    width: fit-content;
    margin-inline: auto;
    border-top: 1px solid #D4D2E3;
    padding-top: 2em;
    padding-inline: 8em;
    text-align: center;
}

.footer__bottom__link {
    color: #ADABC3;
}

@media(max-width:700px) {
    .footer__bottom {
        padding-inline: 2em;
    }
}

@media(max-width: 425px) {
    .footer__bottom {
        padding: 1em;
    }
}

@media print {
    .footer {
        display: none;
    }
}