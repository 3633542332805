.invitation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8em;
}

.invitation__img {
    width: 40%;
}

.invitation__right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5em;
    padding: 2em;
    padding-left: 5em;
    background-color: #EEE;
    aspect-ratio: 2/1;
}

.invitation__heading {
    font-size: 3.63rem;
    font-family: var(--font-primary);
    color: #343434;
    line-height: 1.2;
    max-width: 15ch;
}

.invitation__text,
.invitation__button {
    font-family: var(--font-secondary);
}

.invitation__text {
    font-size: 1.1rem;
    color: #2A2A2B;
    line-height: 1.7;
    max-width: 40ch;
}

.invitation__button {
    color: white;
    text-decoration: none;
    background-color: #7E2121;
    font-weight: bold;
    padding: .8em 1.6em;
    border-radius: 40px;
}

@media(max-width:1120px) {
    .invitation__heading {
        font-size: 2.5rem;
    }

    .invitation__text {
        font-size: .9rem;
    }

    .invitation__button {
        font-size: .85rem;
    }

    .invitation__right {
        padding: 2em;
    }
}

@media(max-width:770px) {
    .invitation {
        flex-direction: column;
    }

    .invitation__img {
        width: 70%;
    }

    .invitation__right {
        width: 70%;
        text-align: center;
        align-items: center;
    }

    .invitation__heading,
    .invitation__text {
        width: 100%;
    }
}

@media(max-width:450px) {
    .invitation__img {
        width: 90%;
    }

    .invitation__right {
        width: 90%;
        padding: 2em .5em;
    }

    .invitation__heading {
        font-size: 2.2rem;
    }

    .invitation__text {
        font-size: .8rem;
    }

    .invitation__button {
        font-size: .75rem;
    }
}