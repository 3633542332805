@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@400;500;600;700&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  /* colors */
  --primary-1: #861115;
  --primary-2: #7E2121;
  --primary-3: #813030;
  --primary-4: #802C2C;
  --primary-5: #731010;

  /* fonts */
  --font-primary: 'Poppins', sans-serif;
  --font-secondary: 'DM Sans', sans-serif;


  --transition: all 200ms ease;
}

.loading {
  display: block;
  width: 300px;
  margin: 5em auto;
}

.error {
  color: white;
  background-color: rgba(205, 0, 0, .7);
  font-size: 2rem;
  font-family: var(--font-secondary);
  padding: 1em;
  width: fit-content;
  margin: 5em auto;
  border-radius: 15px;
}

@media(max-width:770px) {
  .error {
    font-size: 1.3rem;
  }
}