.main {
    padding: 3em 5em;
}

.smallTop {
    font-size: .8rem;
    display: flex;
    align-items: center;
    gap: .5em;
    font-family: var(--font-primary);
    color: #A3A3A3;
}

.heading {
    margin-top: .5em;
    font-size: 3.63rem;
    color: #343434;
    font-family: var(--font-primary);
    line-height: 1;
}

.subheading {
    font-size: 1.2rem;
    color: #000;
    font-family: var(--font-primary);
    margin-top: .5em;
}

.overview {
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.overview__left {
    display: flex;
    flex-direction: column;
    gap: .3em;
    font-size: 1.25rem;
    font-family: var(--font-primary);
}

.overview__text {
    display: flex;
    align-items: center;
    gap: .3em;
}

.overview__price {
    font-size: 2.25rem;
    font-weight: bold;
    color: #813030;
    margin-top: .3em;
}

.overview__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
    gap: .5em;
}

.overview__img {
    width: 100%;
    height: 25em;
    object-fit: contain;
    cursor: pointer;
}

.overview__arrows {
    display: flex;
    align-items: center;
    gap: .5em;
    margin-top: .5em;
}

.overview__buttons {
    display: flex;
    align-items: center;
    gap: 2em;
}

.overview__button {
    font-size: 2rem;
    border: none;
    background: none;
    cursor: pointer;
    color: #5D5A88;
    display: grid;
    place-items: center;
}

.overview__button_facebook,
.overview__button_twitter {
    color: white;
    padding: .35em;
    border-radius: 50%;
    transition: var(--transition);
}

.overview__button_facebook {
    background-color: #4267B2;
}

.overview__button_facebook:hover {
    background-color: #3156A1;
}

.overview__button_twitter {
    background-color: #1DA1F2;
}

.overview__button_twitter:hover {
    background-color: #0C90E1;
}

.more {
    margin-top: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
}

.agent {
    display: flex;
    align-items: center;
    gap: 2.5em;
    color: #000;
    font-family: var(--font-primary);
}

.agent__email__text,
.agent__phone__text {
    color: inherit;
    font-family: inherit;
    font-size: 1.2rem;
}

.agent__img {
    width: 225px;
    height: 225px;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
}

.agent__name {
    font-size: 1.9rem;
    font-weight: bold;
    line-height: 1.2;
}

.agent__job {
    font-size: 1.2rem;
}

.agent__phone,
.agent__email {
    display: flex;
    align-items: center;
    gap: .65em;
}

.agent__email {
    margin-top: .3em;
    margin-bottom: .55em;
}

.agent__phone__text {
    font-weight: bold;
    text-decoration: none;
}

.agent__icon {
    font-size: 1.5rem;
    color: #5D5A88;
}

.more__heading {
    font-size: 1.9rem;
    font-family: var(--font-primary);
    margin-top: 2.3em;
    margin-bottom: .65em;
}

.more__description {
    font-family: var(--font-secondary);
    font-size: 1.1rem;
    line-height: 1.7;
    max-width: 70ch;
    white-space: pre-line;
    overflow-wrap: break-word;
}

.more__right {
    max-width: 400px;
    font-size: 1.1rem;
    font-family: var(--font-secondary);
    line-height: 1.7;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: .4em;
    background-color: #F8F8F8;
    padding: 3em 2em;
}

.imgFullScreenContainer {
    z-index: 100;
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, .5);
    display: grid;
    place-items: center;
}

.imgFullScreen {
    width: 90%;
    height: 70vh;
    object-fit: contain;
}

.imgFullScreenBg {
    position: absolute;
    inset: 0;
    z-index: 90;
}

.imgFullScreenButtons {
    display: flex;
    align-items: center;
    gap: 1em;
    z-index: 95;
}

.imgFullScreenButton {
    font-size: 2.8rem;
    background-color: #7E2121;
    border-radius: 40px;
    border: none;
    color: white;
    display: grid;
    place-items: center;
    padding: .3em;
    cursor: pointer;
}

@media(max-width:1100px) {
    .main {
        padding: 3em 2em;
    }
}

@media(max-width:990px) {
    .overview {
        flex-direction: column;
        align-items: flex-start;
        gap: 3em;
    }

    .overview__right {
        align-self: center;
        width: 100%;
    }

    .more {
        flex-direction: column-reverse;
    }

    .more__right {
        max-width: initial;
        width: 100%;
        margin: 1em auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2em;
    }

    .more__description {
        max-width: 100%;
    }

    .more__right__text {
        display: flex;
        flex-direction: column;
    }
}

@media(max-width:550px) {
    .main {
        padding: 3em 1em;
    }

    .heading {
        font-size: 2rem;
    }

    .subheading {
        font-size: 1.1rem;
    }

    .overview__text {
        font-size: 1.1rem;
    }

    .overview__price {
        font-size: 1.8rem;
    }

    .overview__img {
        height: 20em;
    }

    .more__right {
        grid-template-columns: 1fr;
    }

    .agent {
        flex-direction: column;
    }

    .agent__img {
        width: 180px;
        height: 180px;
    }

    .agent__right {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .agent__name {
        font-size: 1.7rem;
    }

    .agent__job,
    .agent__email__text,
    .agent__phone__text {
        font-size: 1.05rem;
    }

    .agent__icon {
        font-size: 1.35rem;
    }

    .more__heading {
        font-size: 1.7rem;
    }

    .more__description {
        font-size: 1rem;
    }

    .imgFullScreenButton {
        font-size: 2.3rem;
    }
}

@media(max-width:485px) {
    .overview__img {
        height: 15em;
    }
}

@media(max-width:410px) {
    .overview__buttons {
        gap: 1em;
    }

    .overview__button {
        font-size: 1.75rem;
    }

    .overview__button_facebook,
    .overview__button_twitter {
        padding: .3em;
    }
}

@media(max-width:370px) {
    .overview__img {
        height: 13em;
    }
}

@media print {
    .overview__buttons {
        display: none;
    }

    .more__notaPrawna {
        display: none;
    }
}