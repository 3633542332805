.section {
    margin-top: 7em;
}

.section__heading {
    font-size: 3.63rem;
    font-family: var(--font-primary);
    color: #343434;
    margin-left: 1.5em;
    padding-inline: 1em;
}

.section__grid {
    margin-top: 2em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    row-gap: 2em;
}

@media(max-width:1340px) {
    .section__grid {
        grid-template-columns: 1fr;
    }
}

@media(max-width:930px) {
    .section__heading {
        margin-left: 0;
        text-align: center;
    }
}

@media(max-width:535px) {
    .section__heading {
        font-size: 2.5rem;
    }
}