.main {
    padding: 2em 5em;
}

.heading {
    font-size: 3rem;
    font-family: var(--font-primary);
    color: #343434;
}

.info {
    margin-top: 4em;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 3em;
}

.info__child {
    max-width: 50ch;
    text-align: center;
}

.info__heading {
    font-family: var(--font-primary);
    color: #343434;
    font-size: 2rem;
}

.info__text {
    font-family: var(--font-secondary);
    color: #363637;
    font-size: 1.1rem;
    line-height: 1.5;
}

.info__link {
    color: inherit;
}

.form {
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form__top {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3em;
    margin-bottom: 2em;
    flex-wrap: wrap;
}

.form__label {
    font-size: 1.9rem;
    font-family: var(--font-primary);
}

.form__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .15em;
}

.form__textarea {
    margin-top: .15em;
    width: 70%;
    resize: none;
}

.form__input,
.form__textarea {
    border: none;
    background-color: #F3F3F3;
    box-shadow: 0px 3px 10px 0px #00000026;
    border-radius: 20px;
    font-family: var(--font-secondary);
    padding: .5em;
    font-size: 1.9rem;
}

.form__button {
    margin-top: 1.5em;
    font-size: 1.6rem;
    color: white;
    background-color: #7E2121;
    font-weight: bold;
    padding: .5em 3em;
    border: none;
    border-radius: 50px;
    cursor: pointer;
}

.form__error {
    font-size: 2rem;
    margin-top: 1em;
    color: red;
    font-family: var(--font-secondary);
    text-align: center;
}

.notification {
    font-size: 2rem;
    font-weight: bold;
    font-family: var(--font-secondary);
    position: fixed;
    right: 2em;
    top: 8em;
    background-color: rgba(0, 205, 0, .5);
    color: white;
    padding: .7em 2.5em;
    border-radius: 20px;
    transform: scaleX(0);
    transform-origin: right;
    transition: all 250ms ease;
}

.notification_active {
    transform: scaleX(1);
}

@media(max-width:650px) {
    .form__textarea {
        width: 80vw;
    }
}

@media(max-width:520px) {
    .main {
        padding: 2em 1em;
    }

    .heading {
        font-size: 2.5rem;
    }

    .info__heading {
        font-size: 1.7rem;
    }

    .info__text {
        font-size: .95rem;
    }

    .form__input,
    .form__textarea,
    .form__label {
        font-size: 1.4rem;
    }

    .form__input {
        width: 80vw;
    }

    .form__button {
        font-size: 1.25rem;
    }

    .form__error {
        font-size: 1.4rem;
    }
}