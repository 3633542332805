.text {
    font-family: var(--font-secondary);
    font-size: 1.25rem;
    line-height: 1.7;
    max-width: 70ch;
    white-space: pre-line;
    padding: 2em;
}

@media(max-width:600px) {
    .text {
        font-size: 1.1rem;
        padding: 1em;
    }
}

@media(max-width:450px) {
    .text {
        font-size: 1rem;
        padding: .75em;
    }
}