.hero {
    margin-top: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5em;
    padding-inline: 2em;
}

.hero__left {
    max-width: 30ch;
}

.hero__title,
.hero__heading {
    font-family: var(--font-primary);
}

.hero__title {
    color: #A3A3A3;
    font-size: 1.1rem;
}

.hero__heading {
    margin-top: .1em;
    font-size: 5rem;
    line-height: .95;
    color: #2C2C2C;
}

.hero__text {
    font-size: 1.1rem;
    font-family: var(--font-secondary);
    margin-top: 1.3em;
    line-height: 1.6;
    color: #363637;
}

.hero__button {
    display: inline-block;
    font-size: 1.1rem;
    color: white;
    text-decoration: none;
    background-color: #861115;
    font-family: var(--font-secondary);
    padding: .8em 1.6em;
    margin-top: .7em;
    border-radius: 25px;
    font-weight: bold;
}

.hero__img {
    width: 70%;
}

@media(max-width:950px) {

    .hero__title,
    .hero__text,
    .hero__button {
        font-size: .9rem;
    }

    .hero__heading {
        font-size: 3.5rem;
    }
}

@media(max-width:720px) {
    .hero {
        flex-direction: column;
        padding-inline: .5em;
    }

    .hero__left {
        max-width: 60ch;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .hero__img {
        width: 100%;
    }
}

@media(max-width:480px) {
    .hero__left {
        padding-inline: 1.5em;
    }

    .hero__title,
    .hero__text,
    .hero__button {
        font-size: .7rem;
    }

    .hero__heading {
        font-size: 3rem;
    }
}