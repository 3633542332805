.header__top {
    padding: 1.5em 3em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1em;
}

.header__top__link {
    background-color: #F2F1FA;
    padding: .5em;
    display: grid;
    place-items: center;
    border-radius: 9px;
    transition: var(--transition);
}

.header__top__link:hover {
    background-color: #E1E0E9;
}

.header__icon {
    color: #8D8BA7;
    font-size: 1.3rem;
}

.header__bottom {
    display: flex;
    align-items: center;
    padding: 1em 8%;
    gap: 18%;
    box-shadow: 0px 2px 16px 0px #00000026;
}

.header__bottom_mobile {
    display: none;
}

.header__nav {
    display: flex;
    font-size: 1.15rem;
    gap: 1.6em;
}

.header__navLink {
    color: #000;
    text-decoration: none;
    font-family: var(--font-primary);
    font-weight: bold;
}

@media(max-width:1205px) {
    .header__bottom {
        padding: 1em 3em;
        justify-content: space-between;
        gap: 1em;
    }
}

@media(max-width:940px) {
    .header__logo {
        width: 130px;
    }

    .header__bottom {
        display: none;
    }

    .header__bottom_mobile {
        display: block;
        box-shadow: 0px 2px 16px 0px #00000026;
        padding: 1em;
        position: relative;
    }

    .header__bottom_mobile__top {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .header__toggle {
        font-size: 3.5rem;
        color: #7E2121;
    }

    .header__nav_mobile {
        position: absolute;
        left: 0;
        bottom: -100vh;
        height: 100vh;
        display: flex;
        flex-direction: column;
        gap: 1em;
        padding: 2em 1em;
        font-size: 1.2rem;
        background-color: #7E2121;
        box-shadow: 10px 0px 16px 0px #00000026;
        transform: translateX(-115%);
        transition: var(--transition);
        z-index: 10000;
    }

    .header__nav_mobile_active {
        transform: translateX(0);
    }
}

@media(max-width:390px) {
    .header__top {
        justify-content: center;
    }
}

@media print {
    .header {
        display: none;
    }
}