.cookiesPopup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    font-family: var(--font-secondary);
    background-color: rgba(0, 0, 0, .7);

}

.cookiesPopup__text {
    font-size: 1.4rem;
    text-align: center;
    color: white;
}

.cookiesPopup__buttons {
    display: flex;
    align-items: center;
    gap: 1em;
}

.cookiesPopup__button {
    font-size: 1.4rem;
    text-decoration: none;
    border: none;
    background-color: #861115;
    color: white;
    font-weight: bold;
    padding: .3em 1em;
    font-family: inherit;
    border-radius: 150px;
    cursor: pointer;
}

@media(max-width:830px) {

    .cookiesPopup__text,
    .cookiesPopup__button {
        font-size: 1rem;
    }
}

@media(max-width:500px) {
    .cookiesPopup__buttons {
        flex-direction: column;
    }
}