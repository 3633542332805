.offer {
    display: flex;
    align-items: center;
    gap: 2em;
    width: 650px;
    height: 205px;
    background-color: #F8F8F8;
    box-shadow: 0px 4px 9px 0px #00000026;
}

.offer__price__link {
    color: inherit;
    text-decoration: none;
}

.offer__imgLink {
    width: 260px;
    height: 100%;
}

.offer__img {
    width: 100%;
    height: 100%;
}

.offer__right {
    display: flex;
    flex-direction: column;
    gap: .3em;
    font-family: var(--font-primary);
    padding: 1em;
}

.offer__city {
    font-size: 1.9rem;
    font-weight: bold;
    color: #000;
    line-height: 1.2;
}

.offer__type {
    font-size: 1.25rem;
}

.offer__metadata {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1em;
    row-gap: .3em;
    font-size: .8rem;
}

.offer__metadata_column {
    grid-template-columns: 1fr;
}

.offer__priceContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: .5em;
    font-size: 1.65rem;
}

.offer__price {
    color: #813030;
    font-weight: 600;
}

.offer__link__icon {
    color: #802C2C;
}

@media(max-width:680px) {
    .offer {
        flex-direction: column;
        height: fit-content;
        width: 70%;
    }

    .offer__imgLink {
        width: 100%;
        height: 238.39px;
    }
}

@media(max-width:520px) {
    .offer {
        text-align: center;
        width: 85%;
    }

    .offer__metadata {
        grid-template-columns: 1fr;
        justify-items: center;
    }

    .offer__priceContainer {
        flex-direction: column;
    }
}

@media(max-width:380px) {
    .offer__city {
        font-size: 1.7rem;
    }

    .offer__type {
        font-size: 1.1rem;
    }

    .offer__metadata {
        font-size: .8rem;
    }

    .offer__priceContainer {
        font-size: 1.45rem;
    }
}