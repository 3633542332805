.main {
    padding: 2em 4em;
}

.heading {
    font-size: 3rem;
    font-family: var(--font-primary);
    color: #343434;
}

.text,
.text_bold,
.text_list {
    font-size: 1.25rem;
    font-family: var(--font-secondary);
    color: #363637;
    padding-block: .5em;
    max-width: 70ch;
    line-height: 1.7;
}

.text_list {
    padding-left: 1.5em;
}

@media(max-width:630px) {
    .main {
        padding: 2em;
    }

    .heading {
        font-size: 2.5rem;
    }

    .text,
    .text_bold,
    .text_list {
        font-size: 1.05rem;
    }
}

@media(max-width:420px) {
    .main {
        padding: 2em 1em;
    }
}