.main {
    padding: 2em 4em;
}

.heading {
    font-size: 3rem;
}

.heading,
.section__heading {
    font-family: var(--font-primary);
    color: #343434;
}

.section__text,
.section__quote,
.section__list {
    font-family: var(--font-secondary);
    font-size: 1.25rem;
    line-height: 1.7;
    color: #363637;
    max-width: 80ch;
}

.section {
    margin-block: 1.5em;
}

.section__heading {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: .5em;
}

.section__quote {
    display: block;
    margin-bottom: 1em;
}

@media(max-width:630px) {
    .main {
        padding: 2em;
    }

    .heading {
        font-size: 2.5rem;
    }

    .section__heading {
        font-size: 1.4rem;
    }

    .section__text,
    .section__quote,
    .section__list {
        font-size: 1.05rem;
    }
}

@media(max-width:500px) {
    .main {
        padding: 2em 1em;
    }

    .section__list {
        padding-left: 1.5em;
    }
}